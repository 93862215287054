:root {
  --font-main: 'Inter', sans-serif;
  --color-light-text: #5C5C5C;
  --color-blue: #0047FF;
  --border-standard: 1.5px solid black;
  --color-light-background: #f0f0f0;
}

body {
  font-family: var(--font-main);
}

a, .ButtonAsLink {
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  color: var(--color-blue);
  font-family: var(--font-main);
  background: none;
  border: none;
  cursor: pointer;
}

h1 {
  font-size: 30px;
  line-height: 1.5;
  font-weight: 700;
  margin: 0;
  max-width: 650px;
}

p, li {
  line-height: 1.5;
  font-size: 16px;
  font-weight: 400;
  margin: 12px 0;
  max-width: 600px;
}

.Header {
  display: flex;
  height: 120px;
  align-items: center;
  padding: 24px 0;
  box-sizing: border-box;
}

.Header nav {
  margin-left: 120px;
}

.Header > a {
  display: flex;
  align-items: center;
}

.Header nav a {
  margin-right: 100px;
  color: var(--color-light-text);
  font-size: 14px;
  white-space: nowrap;
}

.Logo {

}

.ColumnContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Left, .Right {
  flex-grow: 2;
  width: 50%;
  box-sizing: border-box;
}

.Left {
  padding-right: 24px;
}

.Left section {
  margin-bottom: 40px;
}

.Right {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Right textarea {
    width: 100%;
    border-radius: 10px;
    min-height: 200px;
    padding: 24px;
    box-sizing: border-box;
    border: var(--border-standard);
    font-family: var(--font-main);
    resize: vertical;
}

.Right button {
  display: inline-flex;
  position: relative;
  padding: 12px 70px;
  width: auto;
  margin-top: 24px;
  border: none;
  border-radius: 10px;
  background-color: var(--color-blue);
  color: white;
  font-family: var(--font-main);
  font-weight: 600;
  transition: all 0.1s 0s ease-out;
  cursor: pointer;
}

.Right button:hover {
  transform: scale(1.05);
}

.Application {
  margin: 0 10%;
}

.ActiveLink {
  color: black !important;
  text-decoration: underline;
}

.Graph {
  position: relative;
}

.Graph > img {
  width: 100%;
  max-width: 600px;
}

.GraphMarker {
  display: block;
  width: 0; 
  height: 0; 
  position: relative;
  margin: 0 -10px 3px -10px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid black;
}

.SpinIcon {
  position: absolute;
  right: 30px;
  width: 18px;
  height: 18px;
  margin-left: 12px;
  animation-duration: 1s;
  animation-name: rotateIcon;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}


@keyframes rotateIcon {
  from {
    transform: rotateZ(0);
  }

  to {
    transform: rotateZ(360deg);
  }
}

.ErrorBucket {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}

.OldSurpriseCard {
  border: var(--border-standard);
  border-radius: 10px;
  margin: 10px;
  padding: 10px 20px 20px 20px;
  width: 300px;
}

.OldEntropies {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -10px;
}


.ResultSentences {
  
}


.Sentence {
  display: block;
  cursor: pointer;
  /* position: relative; */
  padding: 5px 10px;
  line-height: 1.5;
  border-left: 2px solid var(--color-light-text);
}

.Sentence:hover {
  background: var(--color-light-background);
}

.SentenceAlt {
  position: absolute;
  padding: 2px 10px;
  /* background: var(--color-blue); */
  color: white;
  font-size: 12px;
  border-radius: 5px;
  margin: 1px 0 0 20px;
  /* bottom: 0; */
  z-index: 100;
  /* left: 50%; */
}

.SurprisingText {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.SurprisingText > span {
  font-weight: 500;
  font-size: 14px;
  padding: 0 8px;
  max-width: 110px;
}